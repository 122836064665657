<template>
  <div style="">
    <b-overlay :show="dataLoading" rounded="sm">
      <b-row class="align-items-center justify-content-between w-100 m-0 mt-1 pl-2 pr-2" style="background-color: #F3F2F7; box-sizing: border-box; padding-top: 10px; padding-bottom: 10px; border-top: 1px solid #ddd">
        <h4 class="m-0" v-if="editing == false"> {{ this.room.title }} </h4>
        <b-col cols="12" md="3" class="mb-2 mb-sm-0" v-if="editing">
          <b-form-input v-model="name" @blur="blurNameRoom"></b-form-input>
        </b-col>


        <b-form-group class="m-0 bg-primary rounded">

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              size="lg"
              @click="upRoom"
              v-b-tooltip.hover title="Переместить помещение выше по иерархии проекта"
          >
            <feather-icon icon="ArrowUpIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Создать новую зону"
              @click="addZone"
          >
            <feather-icon icon="ApertureIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Редактировать название помещения"
              @click="renameRoom"
          >
            <feather-icon icon="EditIcon" size="20"/>
          </b-button>
          <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  v-b-tooltip.hover title="Оставить комментарий"
                  @click="leaveRoomComment"
          >
              <feather-icon icon="MessageSquareIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Дублировать помещение"
              @click="copyRoom"
          >
            <feather-icon icon="CopyIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Скопировать помещение в другой проект"
              @click="copyRoomId(room.id)"
          >
            <feather-icon icon="CornerDownLeftIcon" size="20"/>
          </b-button>
          <b-button
              v-if="room.is_visible == false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Показать помещение"
              @click="changeVisibleRoom"
          >
            <feather-icon icon="EyeIcon" size="20"/>
          </b-button>
          <b-button
              v-if="room.is_visible == true"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Скрыть помещение"
              @click="changeVisibleRoom"
          >
            <feather-icon icon="EyeOffIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Удалить помещение из проекта"
              @click="deleteRoom"
          >
            <feather-icon icon="Trash2Icon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Переместить комнату ниже по иерархии проекта"
              @click="downRoom"
          >
            <feather-icon icon="ArrowDownIcon" size="20"/>
          </b-button>
        </b-form-group>
      </b-row>

      <b-row class="p-1" v-if="commenting" style="background-color: #F3F2F7;">
          <b-col cols="12" md="12">
              <b-form-input
                  class="mb-2 mb-sm-0"
                  style="background-color: #F3F2F7;"
                  v-model="message"
                  @blur="blurCommentRoom"
                  :placeholder="room.comment ? room.comment : 'Комментарий'"
              >
              </b-form-input>
          </b-col>
      </b-row>
      <b-row class="p-1" v-if="!commenting && room.comment" style="background-color: #F3F2F7;">
          <b-col cols="12" md="12">
              <p
                  class="flex-grow-1 m-0"
                  style="font-weight: bold"
              >
                  Комментарий к помещению:
                  <span style="font-weight: normal">{{ this.room.comment }}</span>
              </p>
          </b-col>
      </b-row>

      <b-button v-if="$store.state.app.clipboard.zoneId" variant="success" @click="insertZone">
        Вставить зону сюда
      </b-button>

      <transition-group name="fade">
        <Zone v-if="room.is_visible"
              v-for="zone in sortAreas"
              :zone="zone"
              :key="zone.id"
              @deleteZone="deleteZone"
              @upZone="upZone"
              @downZone="downZone"
              @copyZone="copyZone"></Zone>
      </transition-group>
    </b-overlay>
  </div>
</template>

<script>
import Zone from "@/components/views/projects/nodes/Zone";
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import utils from "@/utils";
import BlInput from "@/components/controls/BlInput";
import _ from 'lodash';
import api from "@/api";
import Vue from "vue";

export default {
  name: "Room",
  directives: {
    Ripple,
  },
  components: {
    BlInput,
    Zone,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BOverlay,


  },
  props: ['room'],
  computed: {
    sortAreas: function() {
      if (this.room.areas_order.length !== this.room.areas.length) {
        this.room.areas_order = this.room.areas.map((item) => item.id)
      }

      return this.room.areas_order.map((id) => this.room.areas.find(item => item.id === id))
    }
  },
  methods: {
    insertZone(){
      const zoneId = this.$store.state.app.clipboard.zoneId
      const roomId = this.$store.state.app.clipboard.roomId
      const projectId = this.$store.state.app.clipboard.projectId
      if (roomId && projectId){
        api.projects.copyZoneToAnotherProject(projectId, roomId, zoneId, this.room.id)
            .then(response=>{
              this.$store.commit('app/SET_CLIPBOARD_ZONE_ID', {roomId: null, projectId: null, zoneId: null})
              const data = response.data;
              if (data.error) {
                utils.notificateError(this, data.message, data.code)
              } else {
                this.updateProjectInfo()
                this.dataLoading = true
                this.$forceUpdate()
                utils.notificate(this, data.message)
                this.dataLoading = false
              }

            })
      }

    },
    // Эта нода
    upRoom() {
      this.$emit('upRoom', this.room)
    },
    downRoom() {
      this.$emit('downRoom', this.room)
    },
    renameRoom() {
      this.name = this.room.title
      this.editing = true
    },
    blurNameRoom() {
      const temp = this.room.title
      if (this.name == '') {
        this.room.title = temp
      } else {
        this.room.title = this.name
      }
      api.projects
          .renameRoom(this.routeId, this.room.id, this.room.title)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.updateProjectInfo()
              this.dataLoading = true
              utils.notificate(this, data.message)
              this.dataLoading = false
              this.$forceUpdate()

            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
      this.editing = false
    },
    leaveRoomComment() {
        this.message = this.room.comment
        this.commenting = true
    },
    blurCommentRoom() {
        this.dataLoading = true
        const empty = ''
        if (this.message == '') {
            this.room.comment = empty
        } else {
            this.room.comment = this.message
        }

        api.projects
            .leaveRoomComment(this.routeId, this.room.id, this.room.title, this.room.comment)
            .then((response) => {
                const data = response.data;
                if (data.error) {
                    throw "Ошибка";
                } else {
                    this.updateProjectInfo()
                    this.dataLoading = true
                    utils.notificate(this, data.message)
                    this.dataLoading = false
                    this.$forceUpdate()
                }
            })
            .catch((err) => {
                this.dataLoading = false
                const data = err.response.data;
                if (data && data.error) {
                    utils.notificateError(self, data.message, data.errorCode);
                }
            });
        this.commenting = false
    },
    copyRoom() {
      this.$emit('copyRoom', {id: this.room.id})
    },
    changeVisibleRoom() {
      this.room.is_visible = !this.room.is_visible
      api.projects
          .changeVisibleRoom(this.routeId, this.room.id, this.room.is_visible)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.updateProjectInfo()
              this.dataLoading = true
              this.$forceUpdate()
              this.dataLoading = false
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },
    deleteRoom() {
      this.$emit('deleteRoom', {id: this.room.id})
    },

    // Дочерняя нода
    upZone(item) {
      const first = this.room.areas_order.indexOf(item.id)

      if (first !== 0) {
        let origin = this.room.areas_order[first]
        this.room.areas_order[first] = this.room.areas_order[first - 1]
        Vue.set(this.room.areas_order, first - 1, origin)

        api.projects
            .updateZoneOrder(this.routeId, this.room.id, this.room.title, this.room.areas_order)
            .then((response) => {
              const data = response.data;
              if (data.error) {
                throw "Ошибка";
              } else {
                this.updateProjectInfo()
                this.$forceUpdate()
                this.$emit('getRooms')
              }
            })
            .catch((err) => {
              this.dataLoading = false
              const data = err.response.data;
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        utils.notificate(this, "Зона первая в списке.")
      }
    },
    downZone(item) {
      const first = this.room.areas_order.indexOf(item.id)
      const end = this.room.areas_order.at(-1)
      const last = this.room.areas_order.indexOf(end)

      if (first !== last) {
        let origin = this.room.areas_order[first]
        this.room.areas_order[first] = this.room.areas_order[first + 1]
        Vue.set(this.room.areas_order, first + 1, origin)

        api.projects
            .updateZoneOrder(this.routeId, this.room.id, this.room.title, this.room.areas_order)
            .then((response) => {
              const data = response.data;
              if (data.error) {
                throw "Ошибка";
              } else {
                this.updateProjectInfo()
                this.$forceUpdate()
                this.$emit('getRooms')
              }
            })
            .catch((err) => {
              this.dataLoading = false
              const data = err.response.data;
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        utils.notificate(this, "Зона последняя в списке.")
      }
    },
    addZone() {
      this.dataLoading = true
      api.projects
          .addZone(this.routeId, this.room.id, 'Новая зона')
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.updateProjectInfo()
              this.dataLoading = true
              utils.notificate(this, data.message)
              this.dataLoading = false
              this.$forceUpdate()
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },
    copyZone(data) {
      this.dataLoading = true
      api.projects
          .copyZone(data.project_pk, data.room_pk, data.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.updateProjectInfo()
              this.dataLoading = true
              this.$forceUpdate()
              utils.notificate(this, data.message)
              this.dataLoading = false
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },
    copyRoomId(id){
      this.$store.commit('app/SET_CLIPBOARD_ROOM_ID', {roomId: id, projectId: this.routeId})
      utils.notificate(this, "Вы скопировали помещение. Теперь можете открыть нужный проект и вставить это помещение")
    },
    deleteZone(data) {
      this.dataLoading = true
      api.projects
          .deleteZone(data.project_pk, data.room_pk, data.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.updateProjectInfo()
              this.dataLoading = true
              this.$forceUpdate()
              utils.notificate(this, data.message)
              this.dataLoading = false
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },


    updateProjectInfo() {
      const self = this;

      this.dataLoading = true
      api.projects
          .getProject(this.routeId)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.$store.commit("app/SET_PROJECT", data.result)
              this.$store.commit('app/UPDATE_SELECTED_ZONE');

              this.dataLoading = false;
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
  },

  mounted() {
    this.routeId = this.$router.currentRoute.params.id
  },
  data() {
    return {
      editing: false,
      commenting: false,
      name: '',
      message: '',
      showRoom: true,
      zones: null,
      routeId: null,
      dataLoading: false,
    }
  }
}
</script>

<style scoped>

</style>
